.li_container{
    display: flex;
    flex-direction: column;
}
.li_container .li_label{
    box-sizing: border-box;
    color: rgba(33, 33, 33, 0.6);
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 16px;
    line-height: 30px;
    font-family: "Gilroy-Medium"; 
}
.login-inner-wrap label.li_label {
    font-family: "Gilroy-SemiBold";
    font-size: 16px;
}
/* my login style */
.li_container .li_input_container .li_input {
    box-sizing: border-box;
    border: 0px solid rgba(27, 32, 44, 0.1);
    display: inline-block;
    font-size: 16px;
    line-height: 30px;
    font-family: "Gilroy-Medium"; 
    outline: none;
    height: 48px;
    color: #212121;
    border-radius: 6px;
    padding: 10px;
    width: 100%;
    background: transparent;
  }
.li_container .li_input_container .li_error_text{
    margin-top: 5px;
    font-size: 12px;
    color: red;
    text-align: left;
    margin-bottom: 0px;
}
/* CSS */
.avatar-container {
  position: relative;
  display: inline-block;
}

.full-name {
  display: none; /* Initially hide the name */
  position: absolute;
  bottom: 100%; /* Position it just above the avatar */
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 2px 4px;
  border-radius: 4px;
  white-space: nowrap;
}

/* Display the full name when hovering over the avatar container */
.avatar-container:not(.no-hover):hover .full-name {
  display: block;
}

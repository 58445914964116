.li_container{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.li_container .li_label{
    box-sizing: border-box;
    color: rgba(33, 33, 33, 0.6);
    margin-bottom: 10px;
    margin-top: 0px;
    font-size: 16px;
    line-height: 30px;
    font-family: "Gilroy-Medium"; 
}
.li_input_container{
    width: 100%;
}
.login-inner-wrap label.li_label {
    font-family: "Gilroy-SemiBold";
    font-size: 16px;
}
.li_container .li_input_container .container_box {
    box-sizing: border-box;
    border: 1px solid rgba(27, 32, 44, 0.1);
    display: inline-block;
    font-size: 16px;
    line-height: 30px;
    font-family: "Gilroy-Medium"; 
    outline: none;
    height: 50px;
    color: rgba(33, 33, 33, 0.6);
    border-radius: 6px;
   padding-top: 10px;
   padding-bottom: 10px;
   padding-right: 10px;
    width: 100%;
    display: flex;
}
/* my login style */
.li_container .li_input_container .li_input {
    box-sizing: border-box;
    border: 0px solid rgba(27, 32, 44, 0.1);
    display: inline-block;
    font-size: 16px;
    line-height: 30px;
    font-family: "Gilroy-Medium"; 
    outline: none;
    height: 48px;
    color: rgba(33, 33, 33, 0.6);
    padding-left: 10px;
    width: 100%;
    margin-top: -10px;
    background: transparent;
  }
  .li_container .li_input_container .container_box:active {
    border: solid 1px #94a4b4;
  }
  .icon_box {
    background: rgba(65, 101, 246, 0.2);
    min-height: 50px;
    min-width: 50px;
    display: flex;
    margin-top: -11px;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
.li_container .li_input_container .li_error_text{
    margin-top: 5px;
    font-size: 12px;
    color: red;
    text-align: left;
    margin-bottom: 0px;
}
.mandatoryColor{
    background: rgb(255 243 229) !important;
}
.toggle {
width: 122px;
height: 42px;
/* UI Properties */
background: transparent linear-gradient(180deg, #0F3FFF 0%, #93A9FF 100%) 0% 0% no-repeat padding-box;
border-radius: 21px;
opacity: 1;
margin-left: 40px;
}



.toggle-icon {
  margin-top: -70px;
  margin-left:60px;
  display: block;
}


.toggle-labels {
  display: flex;
  flex-direction: row;
  width: 100px;
  position: relative;
  z-index: 1;
}

.toggle-yes,
.toggle-no {
  flex: 1;
  text-align: center;
  font-weight: bold;
}

.toggle.toggled-yes .toggle-slider {
  transform: translateX(60px);
}

.toggle.toggled-yes .toggle-icon {
  transform: translateX(-80px);
}

.toggle.toggled-no .toggle-slider {
  transform: translateX();
}

.toggle.toggled-no .toggle-icon {
  transform: translateX(-95px);
}

.toggle.toggled-yes {

  width: 107px;
  height: 42px;
  /* UI Properties */
  background: transparent linear-gradient(180deg, #FF8D00 0%, #FFD5A2 100%) 0% 0% no-repeat padding-box;
  border-radius: 21px;
  opacity: 1;
}

.toggle.toggled-no {
  /* UI Properties */
  background: transparent linear-gradient(180deg, #0F3FFF 0%, #93A9FF 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
}

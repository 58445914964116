/* General Styles */
.kyc-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.kyc-step {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 90vh;
}

.kyc-step h2 {
  margin-bottom: 16px;
  color: #333;
}

.kyc-step p {
  margin-bottom: 12px;
  color: #555;
}

.kyc-step ul {
  text-align: left;
  margin-bottom: 20px;
  padding-left: 20px;
}

.kyc-step ul li {
  margin-bottom: 8px;
}

/* Document Upload Area */
.document-upload-area {
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  margin: 20px 0;
  cursor: pointer;
  transition: border-color 0.3s;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.document-upload-area:hover {
  border-color: #007bff;
}

.document-upload-area.drag-over {
  background-color: #f0f8ff;
  border-color: #007bff;
  transform: scale(1.02);
}

.upload-icon {
  font-size: 40px;
  margin-bottom: 15px;
}

.document-upload-area p {
  margin: 5px 0;
  color: #666;
}

/* Camera Elements */
.camera-view {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  aspect-ratio: 16/9;
  background: #000;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.camera-view video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.camera-error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  text-align: center;
  z-index: 10;
}

.selfie-container {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selfie-frame {
  position: relative;
  width: 300px;
  height: 400px;
  margin: 0 auto 20px;
  border: 3px solid #007bff;
  border-radius: 50%;
  overflow: hidden;
  background: #000;
}

.selfie-frame .camera-view {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  margin: 0;
}

.selfie-frame video {
  position: absolute;
  transform: translate(-50%, -50%) scaleX(-1);
}

.start-camera-container {
  text-align: center;
  margin: 20px 0;
}

/* Preview Section */
.preview-section {
  margin: 20px 0;
}

.preview-section img {
  max-width: 100%;
  border-radius: 8px;
}

.image-preview-section {
  position: relative;
  width: 300px;
  height: 400px;
  margin: 0 auto 20px;
  border: 3px solid #007bff;
  border-radius: 50%;
  overflow: hidden;
  background: #000;
}

.image-preview-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

/* PDF Preview */
.pdf-preview {
  width: 100%;
  height: 500px;
  border: none;
  border-radius: 8px;
}

/* Buttons */
.button {
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  background: #007bff;
  color: white;
  cursor: pointer;
  margin: 5px;
}

.button:disabled {
  background: #ccc;
}

.button-group {
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  width: max-content;
  bottom: 10px;
  gap: 25px;
}

.instructions {
  background: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.instructions h3 {
  color: #333;
  margin: 10px 0;
  font-size: 1.1em;
}

.instructions ul {
  margin-bottom: 15px;
}

.camera-button {
  margin-top: 15px;
}

.selfie-container .button-group {
  width: 100%;
  justify-content: center;
  margin-top: 20px;
}

@media (max-width: 600px) {
  .kyc-container {
    padding: 10px;
  }

  .button-group {
    flex-direction: row;
  }

  .pdf-preview {
    height: 400px;
  }

  .camera-view {
    aspect-ratio: 9/16;
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.css-iceqnf {
  position: unset !important;
  margin-top: 80px !important;
}
/*bubble animation*/

.bubble-holder {
  width: 100%;
  height: 100vh;
  position: absolute;
  overflow: hidden;
  z-index: 0;
}

.bubble-1 {
  /* left: 8%; */
  left: -10%;
  animation-delay: 1s;
}
.bubble-1 .bubble-animation-y {
  animation-duration: 14s;
  animation-delay: 0s;
}

.bubble-2 {
  /* left: 40%; */
  left: 20%;
  animation-delay: 2s;
} 
.bubble-2 .bubble-animation-y {
  animation-duration: 12s;
  animation-delay: 2s;
}

.bubble-3 {
  right: 15%;
  animation-delay: 1s;
}
.bubble-3 .bubble-animation-y {
  animation-duration: 10s;
  animation-delay: 1.5s;
}

.bubble-4 {
  /* left: 8%; */
  left: -10%;
  animation-delay: 2s;
}
.bubble-4 .bubble-animation-y {
  animation-duration: 12s;
  animation-delay: 3s;
}

.bubble-5 {
  /* left: 40%; */
  left: 20%;
  animation-delay: 1s;
}
.bubble-5 .bubble-animation-y {
  animation-duration: 10s;
  animation-delay: 0;
}

.bubble-6 {
  left: 45%;
  animation-delay: 2s;
}
.bubble-6 .bubble-animation-y {
  animation-duration: 14s;
  animation-delay: 3.5s;
}

.bubble-7 {
  right: 15%;
  animation-delay: 1s;
}
.bubble-7 .bubble-animation-y {
  animation-duration: 11s;
  animation-delay: 3s;
}

.bubble-8 {
  right: 5%;
  animation-delay: 2s;
}
.bubble-8 .bubble-animation-y {
  animation-duration: 7s;
  animation-delay: 3s;
}

.bubble-container {
  position: absolute;
  bottom: -250px;
}

/* .bubble-large,
.bubble-small {
  border-radius: 100%;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1),
    inset 0px 10px 30px 5px rgba(96, 147, 195, 1);
} */

.bubble-large { 
  width: 185px;
  height: 185px;
}

.bubble-small {
  width: 150px;
  height: 150px;
}
.fullImg{
  width: 100%;
  height: 100%;
}

.bubble-animation-x {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bubbleXAnimFrames;
  animation-timing-function: ease;
  animation-direction: alternate;
}

.bubble-animation-y {
  animation-iteration-count: infinite;
  animation-name: bubbleYAnimFrames;
  animation-timing-function: linear;
}

.bubble-animation-y {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Apply diagonal animation to the bubbles */
.bubble-animation-y {
  animation-name: bubbleDiagonalAnimFrames; /* Use diagonal animation */
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes bubbleXAnimFrames {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(50px);
  }
}

/* @keyframes bubbleYAnimFrames {
  0% {
    transform: translateY(0);
  }
  50% {
    opacity: 85%;
  }
  100% {
    transform: translateY(-1250px);
    opacity: 0;
  }
}

@keyframes bubbleDiagonalAnimFrames {
  0% {
    transform: translate(0, 0); 
    opacity: 1;
  }
  50% {
    opacity: 85%;
  }
  100% {
    transform: translate(
      1250px,
      -1250px
    ); 
    opacity: 0;
  }
} */

@keyframes bubbleYAnimFrames {
  0% {
    transform: translateY(0);
  }
  50% {
    opacity: 85%;
  }
  100% {
    transform: translateY(-100vh); /* Move up relative to the viewport height */
    opacity: 0;
  }
}

@keyframes bubbleDiagonalAnimFrames {
  0% {
    transform: translate(0, 0); /* Start at the original position */
    /* opacity: 1; Fully visible at start */
  }
  50% {
    /* opacity: 85%; Slightly fade at midpoint */
  }
  100% {
    transform: translate(65vw, -130vh);
    /* transform: translate(100vw, -100vh); Move diagonally relative to screen size */
    /* opacity: 0; Fully transparent at end */
  }
}



@media (max-width: 768px) {
  .bubble-large {
    width: 120px; /* Smaller size for tablets */
    height: 120px;
  }
  .bubble-small {
    width: 80px; /* Smaller size for mobiles */
    height: 80px;
  }
}

@media (max-width: 480px) {
  .bubble-large {
    width: 90px; /* Smaller size for smaller devices */
    height: 90px;
  }
  .bubble-small {
    width: 60px;
    height: 60px;
  }
}


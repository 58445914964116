.predictionList {
    position: absolute !important;
    z-index: 1000;
    background-color: white; /* Adjust this if you have a different background color */
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12); /* Example shadow */
    max-height: 150px;
    overflow-y: auto;
    width: 80%;
  }

.mapContent {
    padding: 25px;
    padding-top: 10px !important;
}

.addressInput {
    padding-bottom: 10px !important;
}

@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");

.DateRangePicker {
  display: flex;
  flex-basis: max-content;
  z-index: 5;
  @media (max-width: 768px) {
    padding: 10px 0;
  }
}
.DateRangePickerInput {
  display: flex !important;
}
.DateInput {
  width: unset !important;
}
.DateRangePickerInput_arrow {
  display: flex !important;
  align-items: center !important;
}
@font-face {
  font-family: "Gilroy-Bold";   /*Can be any text*/
  src: local("Gilroy-Bold"),
    url("./fonts/Gilroy-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-Regular";   /*Can be any text*/
  src: local("Gilroy-Regular"),
    url("./fonts/Gilroy-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-ExtraBold";   /*Can be any text*/
  src: local("Gilroy-ExtraBold"),
    url("./fonts/Gilroy-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-Medium";   /*Can be any text*/
  src: local("Gilroy-Medium"),
    url("./fonts/Gilroy-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Gilroy-SemiBold";   /*Can be any text*/
  src: local("Gilroy-SemiBold"),
    url("./fonts/Gilroy-SemiBold.ttf") format("truetype");
}
/* Poppins font include */
@font-face {
  font-family: "Poppins-Bold";   /*Can be any text*/
  src: local("Poppins-Bold"),
    url("./fonts/Poppins-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Italic";   /*Can be any text*/
  src: local("Poppins-Italic"),
    url("./fonts/Poppins-Italic.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Light";   /*Can be any text*/
  src: local("Poppins-Light"),
    url("./fonts/Poppins-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Light";   /*Can be any text*/
  src: local("Poppins-Light"),
    url("./fonts/Poppins-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Medium";   /*Can be any text*/
  src: local("Poppins-Medium"),
    url("./fonts/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Regular";   /*Can be any text*/
  src: local("Poppins-Regular"),
    url("./fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-SemiBold";   /*Can be any text*/
  src: local("Poppins-SemiBold"),
    url("./fonts/Poppins-SemiBold.ttf") format("truetype");
}


* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
body {
  background-color: #FAFCFF !important;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(33, 33, 33, 0.4);
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgba(33, 33, 33, 0.4);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: rgba(33, 33, 33, 0.4);
}
.notifyBubble{
  align-self: flex-start;
  border-radius: 50%;
  color: #fff;
  font-size: 0.6em;
  height: 10px;
  line-height: 10px;
  position: absolute;
  width: 10px;
  background-color: red;
}
.pr-Reenvio{ right: 19px; }
.custom-date-picker-wrapper .react-datepicker__input-container input {
  background-color: rgba(65, 101, 246, 0.15) !important; /* Change background color */
  color: #4165F6 !important; /* Change text color */
  height: 30px;
  width: 110px; /* Adjust the width of the input field */
  font-weight: bold;
  border-radius: 8px;
  font-size: 11px;
  font-family: "Gilroy-Medium";
  text-transform: capitalize;
}
.custom-date-picker-wrapper {
  position: relative; /* Ensure relative positioning */
}
.react-datepicker-popper {
  z-index: 9999 !important;
}
.custom-date-picker-wrapper .date-picker-icon {
  position: absolute;
  top: 50%;
  left: 10px; /* Adjust the left position as needed */
  transform: translateY(-50%);
  width: 20px; /* Set the width of the icon */
  height: 20px; /* Set the height of the icon */
}

.custom-date-picker-wrapper .react-datepicker__input-container input {
  background-color: #D9E0FD !important;
  color: #4165F6 !important;
  border-radius: 8px; /* Add border-radius for round edges */
  padding-left: 30px; /* Add padding to make space for the icon */
}
.custom-date-picker-wrapper .react-datepicker__input-container input::placeholder {
  color: #4165F6; /* Set placeholder color to #4165F6 */
  font-weight: bold; /* Make placeholder text bold */
}

.MuiGrid-item.folio-fields-section{
  padding-top: 0 !important;
}
.MuiGrid-item.folio-fields-section .li_container .li_label {
  margin-top: 0 !important;
}
.input_mask_box{
    box-sizing: border-box;
    border: 0px;
    display: inline-block;
    font-size: 16px;
    line-height: 30px;
    font-family: "Gilroy-Medium"; 
    outline: none;
    height: 50px;
    color: rgba(33, 33, 33, 0.6);
    border-radius: 6px;
   padding-top: 10px;
   padding-bottom: 10px;
   padding-right: 10px;
    width: 100%;
    display: flex;
    padding: 10px;
    background: rgba(65, 101, 246, 0.06);
}
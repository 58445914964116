.clddi_input_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.clddi_input_container .clddi_label {
  /* padding-top: 10px; */
  padding-bottom: 10px;
  box-sizing: border-box;
  font-family: "Gilroy-Medium";
  font-size: 16px;
  color: rgba(33, 33, 33, 0.6);
}
.clddi_input_container .clddi_div1 {
  width: 100%;
  float: left;
  margin-top: 10px;
}
.clddi_input_container .clddi_div1 .clddi_container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.li_container .li_input_container .container_box:active {
  border: solid 1px #94a4b4;
}
.clddi_input_container .clddi_div1 .clddi_container .clddi_input {
  position: relative;
  all: unset;
  width: 100%;
  border: 0px solid rgba(27, 32, 44, 0.1);
  padding-top: 10px;
  padding-left: 10px;
  font-size: 16px;
  line-height: 30px;
  z-index: 1;
  cursor: pointer;
  color: #212121;
  font-family: "Gilroy-Medium";
  height: 38px;
  margin-top: -10px;
  border-radius: 6px;
}

.clddi_input_container .clddi_div1 .clddi_container .clddi_icon {
  position: absolute;
  right: 5px;
  z-index: 1;
}

.clddi_input_container .clddi_error_text {
  margin-top: 5px;
  font-size: 12px;
  color: red;
  text-align: left;
}
